import React from "react";

const MerktaalScoreIcon = ({ minimumScore, score, animationOrder }) => {
  const animationStyling = {
    animationName: "fadeInDown",
    animationDuration: "300ms",
    animationTimingFunction: "linear",
    animationDelay: `${300 * animationOrder}ms`,
    animationFillMode: "both"
  };
  if(score == null) {
    return <span className="w-4 mr-2">
    </span>;
  } else if(score >= minimumScore) {
    // bi-check from Bootstrap icons
    return <span title={score} className="text-green w-4 mr-0.5 relative" style={{left: "-2px", ...animationStyling}}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="h-6 w-6" viewBox="0 0 16 16">
        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
      </svg>
    </span>;
  } else {
    return <span title={score} className="w-4 mr-0.5" style={animationStyling}>
      <span className="flex items-end justify-end">
        <span className="relative inline-flex rounded-full h-2 w-2 bg-red"></span>
      </span>
    </span>;
  }
};

export default MerktaalScoreIcon;
