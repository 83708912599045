import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";

import CKEDITOR from "ckeditor";

import createMerktaalStore, { reset } from "../lib/merktaal_store";

import MerktaalDocumentControls from "../components/MerktaalDocumentControls";
import MerktaalSidepanel from "../components/MerktaalSidepanel";

CKEDITOR.on("instanceReady", ({editor}) => {
  const ktEditor = editor.ktPluginInstance.ktEditor;
  const merktaalStore = createMerktaalStore(ktEditor);

  // Override handleMenuOpen so that all highlighted annotations are
  // in effect hidden
  const handleMenuOpen = ktEditor.textElementWrapper.handleMenuOpen;
  ktEditor.textElementWrapper.setHandleMenuOpen((event, annotationElement) => {
    const annotationType = ktEditor.ktConfig.annotationTypes.find((at) => at.id === annotationElement.annotation().typeId);
    if(annotationType.display.markType === "highlight") {
      annotationElement.foundAnnotations.shift();
    }

    if(annotationElement.annotation()) {
      handleMenuOpen(event, annotationElement);
    }
  });

  const merktaalSidepanel = document.getElementById("merktaal-side-panel");
  const merktaalDocumentControlsElement = document.getElementById("merktaal-document-controls");

  ktEditor.onRender = () => {
    ReactDOM.render(
      <Provider store={merktaalStore}>
        <MerktaalSidepanel editor={ktEditor} />
        { ReactDOM.createPortal(<MerktaalDocumentControls editor={ktEditor} />, merktaalDocumentControlsElement) }
      </Provider>,
      merktaalSidepanel
    );
  };
  ktEditor.onReset = () => {
    merktaalStore.dispatch(reset());
  };
  ktEditor.onConfigLoaded();
  ktEditor.onRender();
});
