import { configureStore, createAction } from "@reduxjs/toolkit";

import merktaalMiddleware from "./merktaal_middleware";

import selectedAnnotationCategoryReducer from "./selected_annotation_category_slice";
import annotationTypesReducer from "./annotation_types_slice";
import documentTypeReducer from "./document_type_slice";

const createStore = (ktEditor) => {
  return configureStore({
    reducer: {
      selectedAnnotationCategory: selectedAnnotationCategoryReducer,
      annotationTypes: annotationTypesReducer,
      documentType: documentTypeReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(merktaalMiddleware(ktEditor))
  });
};

export default createStore;

export const getAnnotationTypes = (state) => state.annotationTypes;
export const getSelectedAnnotationCategory = (state) => state.selectedAnnotationCategory;
export const getDocumentType = (state) => state.documentType;

export const reset = createAction("reset");
