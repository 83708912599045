import React from "react";

import { useSelector, useDispatch } from "react-redux";

import classNames from "classnames";

import { toggle, getSelectedAnnotationCategoryId } from "../lib/selected_annotation_category_slice";
import { toggle as toggleAnnotationType, getEnabledAnnotationTypes } from "../lib/annotation_types_slice";
import { getDocumentType } from "../lib/document_type_slice";

import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";

import Bar from "./Bar";
import MerktaalScoreIcon from "./MerktaalScoreIcon";
import MerktaalDescription from "./MerktaalDescription";

const ANNOTATION_CATEGORIES_COLORS = {
  "kpn-merktaal-matter-of-fact": "#b2e5fc",
  "kpn-merktaal-warm": "#ffcdd2",
  "kpn-merktaal-confident": "#d1c4e9",
  "kpn-merktaal-style": null,
  "kpn-merktaal-readability": "#66bb6a"
};

const MerktaalAnnotationTypes = ({annotationTypes, problemSizes}) => {
  const dispatch = useDispatch();

  const enabledAnnotationTypes = useSelector(getEnabledAnnotationTypes);
  const handleToggleAnnotationType = (annotationTypeId) => dispatch(toggleAnnotationType(annotationTypeId));

  return <React.Fragment>
    {annotationTypes.map((annotationType) => {
      const problemSize = problemSizes.find((problemSize) => problemSize.name === annotationType.id);
      const problemSizeScore = problemSize ? problemSize.score : 0;
      const normalizedProblemSize = Math.abs(problemSizeScore) > 2 ? 2 : Math.abs(problemSizeScore);
      const filled = normalizedProblemSize / 2;

      const enabled = enabledAnnotationTypes.has(annotationType.id);
      const handleToggle = (event) => {
        event.preventDefault();
        handleToggleAnnotationType(annotationType.id);
      };

      return <React.Fragment key={annotationType.id}>
        <div className="pl-6 flex items-center py-2 max-h-6 cursor-pointer" onClick={handleToggle}>
          <Bar
            slider={0}
            filled={filled}
            fillColor={enabled ? annotationType.display.color : "#e8e8e8"}
            sliderWidth="0"
            sliderHeight="0"
          />
        </div>
        <div className={classNames("cursor-pointer text-sm h-6", {"text-gray": !enabled})} onClick={handleToggle}>
          {annotationType.display.name}
        </div>
      </React.Fragment>;
    })}
  </React.Fragment>;
};

const MerktaalCategory = ({annotationCategory, annotationTypes, annotationCategoryScore, problemSizes, enabled, onToggle, animationOrder, hasEnabledAnnotationCategory}) => {
  const annotationCategoryColor = ANNOTATION_CATEGORIES_COLORS[annotationCategory.id];
  const handleToggle = (event) => {
    event.preventDefault();
    onToggle();
  };
  const documentType = useSelector(getDocumentType);
  return <React.Fragment>
    <div className="flex cursor-pointer" onClick={handleToggle}>
      <MerktaalScoreIcon
        minimumScore={annotationCategory.minimumScores[documentType]}
        score={annotationCategoryScore}
        animationOrder={animationOrder}
      />
      <span className={classNames("font-bold", {"text-gray": hasEnabledAnnotationCategory && !enabled})}>
        {annotationCategory.display}
      </span>
    </div>
    <div className="flex items-center py-2 h-8">
      {annotationCategoryColor && <Bar
        fillColor={annotationCategoryColor}
        filled={(annotationCategoryScore ?? 0) / 6}
        slider={annotationCategory.minimumScores[documentType] / 6}
        sliderWidth="0.5rem"
        sliderHeight="1.5rem"
      />}
    </div>
    {
      enabled &&
        <MerktaalAnnotationTypes annotationTypes={annotationTypes} problemSizes={problemSizes} />
    }
  </React.Fragment>;
};

const scoreForAssignmentType = (assignments, type) => {
  const assignment = assignments.find((assignment) => assignment.type === type);
  const value = assignment ? parseFloat(assignment.labels[0].name) : 0.0;
  return value < 0.0 ? 0.0 : value;
};

const MerktaalCategories = ({annotationTypes, annotationCategories, assignments, problemSizes}) => {
  const dispatch = useDispatch();

  const sortedAnnotationTypes = sortBy(
    annotationTypes.filter((annotationType) => annotationType.display.markType === "underline"),
    (annotationType) => annotationType.display.displayOrder
  );

  const groupedAnnotationTypes = groupBy(sortedAnnotationTypes, (annotationType) => annotationType.checkType);

  const annotationCategoryById = (id) => annotationCategories.find((annotationType) => annotationType.id === id);

  const enabledAnnotationCategory = useSelector(getSelectedAnnotationCategoryId);
  const toggleAnnotationCategory = (annotationCategoryId) => dispatch(toggle(annotationCategoryId));

  return <div className="grid-cols-2 grid gap-x-2">
    {Object.keys(groupedAnnotationTypes).map((annotationCategoryId, i) => {
      const annotationCategory = annotationCategoryById(annotationCategoryId);
      const assignmentId = annotationCategoryId === "kpn-merktaal-readability" ? "b1" : annotationCategoryId.replace("kpn-merktaal-", "");
      const score = assignments.length ? scoreForAssignmentType(assignments, assignmentId) : null;

      return <MerktaalCategory
        onToggle={() => toggleAnnotationCategory(annotationCategoryId)}
        enabled={enabledAnnotationCategory === annotationCategoryId}
        hasEnabledAnnotationCategory={enabledAnnotationCategory != null}
        annotationCategory={annotationCategory}
        annotationTypes={groupedAnnotationTypes[annotationCategoryId]}
        annotationCategoryScore={score}
        problemSizes={problemSizes}
        animationOrder={i}
        key={annotationCategoryId} />;
    })}
  </div>;
};

const MerktaalSidepanel = ({editor}) => {
  if(editor.loading && editor.ktConfig == null) {
    return null;
  }
  return <div className="flex flex-col justify-between h-full" style={{height: "calc(566px + 46px + 8px + 8px)"}}>
    <div className="container-block border pt-8">
      <MerktaalCategories
        annotationTypes={editor.ktConfig.annotationTypes}
        annotationCategories={editor.ktConfig.annotationCategories}
        assignments={editor.responseOptions.assignments}
        problemSizes={editor.problemSizes}
      />
    </div>
    <MerktaalDescription
      evaluated={editor.responseOptions.assignments.length > 0}
      assignments={editor.responseOptions.assignments}
      annotationCategories={editor.ktConfig.annotationCategories}
      annotationTypes={editor.ktConfig.annotationTypes}
    />
  </div>;
};

export default MerktaalSidepanel;

export { ANNOTATION_CATEGORIES_COLORS };
