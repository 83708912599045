import { createSlice, current } from "@reduxjs/toolkit";

import { getAnnotationTypes } from "./merktaal_store";

export const annotationTypesSlice = createSlice({
  name: "annotationTypes",
  initialState: {
    enabledAnnotationTypes: []
  },
  reducers: {
    toggle: (state, action) => {
      const annotationTypeId = action.payload;
      const set = new Set(current(state.enabledAnnotationTypes));

      if(set.has(annotationTypeId)) {
        set.delete(annotationTypeId);
      } else {
        set.add(annotationTypeId);
      }
      state.enabledAnnotationTypes = Array.from(set);
    }
  },
  extraReducers: {
    reset: (state, _action) => {
      state.enabledAnnotationTypes = [];
    },
  },
});

export const { toggle } = annotationTypesSlice.actions;

export default annotationTypesSlice.reducer;

export const getEnabledAnnotationTypes = (state) => new Set(getAnnotationTypes(state).enabledAnnotationTypes);
