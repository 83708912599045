import React from "react";

import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";

import { useSelector } from "react-redux";

import { getSelectedAnnotationCategoryId } from "../lib/selected_annotation_category_slice";
import { getDocumentType } from "../lib/document_type_slice";

import { ANNOTATION_CATEGORIES_COLORS } from "./MerktaalSidepanel";

const scoreForAssignmentType = (assignments, type) => {
  const assignment = assignments.find((assignment) => assignment.type === type);
  const value = assignment ? parseFloat(assignment.labels[0].name) : 0.0;
  return value < 0.0 ? 0.0 : value;
};

const useScoreIsOk = ({ group, assignments, annotationCategories }) => {
  const documentType = useSelector(getDocumentType);

  const assignmentId = group === "kpn-merktaal-readability" ? "b1" : group.replace("kpn-merktaal-", "");
  const score = scoreForAssignmentType(assignments, assignmentId);
  const annotationCategory = annotationCategories.find((annotationType) => annotationType.id === group)
  const minimumScore = annotationCategory.minimumScores[documentType];
  return score >= minimumScore;
};

const U = ({ children }) => (
  <span className="border-b-2 border-blue">{ children }</span>
);

const H = ({ children, t}) => (
  <span style={{ backgroundColor: ANNOTATION_CATEGORIES_COLORS[`kpn-merktaal-${t}`] }}>
    {children}
  </span>
);

const G = ({ children }) => (
  <span className="text-green">{ children }</span>
);

const AboutMerktaal = () => (
  <p>
    Meer over de KPN Merktaal: <a href="https://www.kpnbrandportal.nl" target="_blank">
      www.kpnbrandportal.nl
    </a>
  </p>
);

const Intro = () => (
  <React.Fragment>
    <p>
      <G>Met Klinkende Taal schrijf je sneller teksten in de KPN
        Merktaal.</G> <br />
      Schrijf je tekst, kies wat voor tekst het is, en evalueer.
    </p>
  </React.Fragment>
);

const MatterOfFact = ({ assignments, annotationCategories }) => {
  const isOk = useScoreIsOk({
    group: "kpn-merktaal-matter-of-fact",
    assignments, annotationCategories
  });

  if(isOk) {
    return <MatterOfFactOk />;
  } else {
    return <MatterOfFactNotOk />;
  }
};

const MatterOfFactOk = () => (
  <React.Fragment>
    <p>
      <G>Je tekst is al nuchter.</G> Je hoeft dus niets te doen. Maar het mag wel.
    </p>
    <p>
      De nuchtere woorden hebben we <H t="matter-of-fact">blauw</H> aangestreept.
      Die maken de tekst nuchter.
      Klik op de modules om de <U>onderstrepingen</U> te zien die de tekst minder nuchter maken.
    </p>
  </React.Fragment>
);

const MatterOfFactNotOk = () => (
  <React.Fragment>
    <p>
      <G>Je tekst is nog niet nuchter genoeg.</G>
    </p>
    <p>
      De nuchtere woorden zie je <H t="matter-of-fact">blauw</H> aangestreept. Dat helpt!
    </p>
    <p>
      Er is meer dat helpt bij een nuchtere tekst. Klik de modules aan,
      werk een aantal <U>onderstrepingen</U> weg
      en evalueer opnieuw. Je kunt ook meer nuchtere woorden toevoegen.
    </p>
  </React.Fragment>
);

const Warm = ({ assignments, annotationCategories }) => {
  const isOk = useScoreIsOk({
    group: "kpn-merktaal-warm",
    assignments, annotationCategories
  });

  if(isOk) {
    return <WarmOk />;
  } else {
    return <WarmNotOk />;
  }
};

const WarmOk = () => (
  <React.Fragment>
    <p>
      <G>Je tekst is al warm.</G> Je hoeft dus niets te doen. Maar het mag wel.
    </p>
    <p>
      De warme woorden hebben we <H t="warm">lichtrood</H> aangestreept. Die maken de tekst warm.
      Klik op de modules om de <U>onderstrepingen</U> te zien die de tekst minder warm maken.
    </p>
  </React.Fragment>
);

const WarmNotOk = () => (
  <React.Fragment>
    <p>
      <G>Je tekst is nog niet warm genoeg.</G>
    </p>
    <p>
      De warme woorden zie je <H t="warm">lichtrood</H> aangestreept. Dat helpt!
    </p>
    <p>
      Er is meer dat helpt bij een warme tekst. Klik de modules aan,
      werk een aantal <U>onderstrepingen</U> weg
      en evalueer opnieuw. Je kunt ook meer warme woorden toevoegen.
    </p>
  </React.Fragment>
);

const Confident = ({ assignments, annotationCategories }) => {
  const isOk = useScoreIsOk({
    group: "kpn-merktaal-confident",
    assignments, annotationCategories
  });

  if(isOk) {
    return <ConfidentOk />;
  } else {
    return <ConfidentNotOk />;
  }
};

const ConfidentOk = () => (
  <React.Fragment>
    <p>
      <G>Je tekst is al zelfverzekerd.</G> Je hoeft dus niets te doen. Maar het mag wel.
    </p>
    <p>
      De zelfverzekerde woorden hebben we <H t="confident">paars</H> aangestreept. Die maken de tekst zelfverzekerd.
      Klik op de modules om de <U>onderstrepingen</U> te zien die de tekst minder zelfverzekerd maken.
    </p>
  </React.Fragment>
);

const ConfidentNotOk = () => (
  <React.Fragment>
    <p>
      <G>Je tekst is nog niet zelfverzekerd genoeg.</G>
    </p>
    <p>
      De zelfverzekerde woorden zie je <H t="confident">paars</H> aangestreept. Dat helpt!
    </p>
    <p>
      Er is meer dat helpt bij een zelfverzekerde tekst. Klik de modules aan,
      werk een aantal <U>onderstrepingen</U> weg
      en evalueer opnieuw. Je kunt ook meer zelfverzekerde woorden toevoegen.
    </p>
  </React.Fragment>
);

const Readability = ({ assignments, annotationCategories }) => {
  const isOk = useScoreIsOk({
    group: "kpn-merktaal-readability",
    assignments, annotationCategories
  });

  if(isOk) {
    return <ReadabilityOk />;
  } else {
    return <ReadabilityNotOk />;
  }
};

const ReadabilityOk = () => (
  <React.Fragment>
    <p>
      <G>Je tekst is al B1.</G> Je hoeft niets te doen. Maar het mag wel.
    </p>
    <p>
      Klik de modules aan om <U>onderstrepingen</U> te zien van woorden en zinnen die niet B1 zijn.
    </p>
  </React.Fragment>
);

const ReadabilityNotOk = () => (
  <React.Fragment>
    <p>
      <G>Je tekst is niet B1.</G>
    </p>
    <p>
      Klik de modules aan. Je ziet dan <U>onderstrepingen</U> van woorden en zinnen die niet B1 zijn. Werk een aantal onderstrepingen weg en evalueer dan opnieuw.
    </p>
  </React.Fragment>
);

const Style = ({ assignments, annotationCategories }) => {
  const isOk = useScoreIsOk({
    group: "kpn-merktaal-style",
    assignments, annotationCategories
  });

  if(isOk) {
    return <StyleOk />;
  } else {
    return <StyleNotOk />;
  }
};

const StyleOk = () => (
  <p>
    <G>Je tekst voldoet al aan de Schrijfwijzer.</G>

    <AboutMerktaal />
  </p>
);

const StyleNotOk = () => (
  <React.Fragment>
    <p>
      <G>Je tekst voldoet nog niet aan de Schrijfwijzer.</G>
    </p>
    <p>
      Klik de modules aan om <U>onderstrepingen</U> te zien van woorden en zinnen die niet volgens de Schrijfwijzer zijn.
    </p>

    <AboutMerktaal />
  </React.Fragment>
);

const DOCUMENT_TYPE_NAMES = {
  inspire: "Inspireren en verleiden",
  argue: "Argumenteren",
  associate: "Associëren",
  inform: "Informeren"
};
const GROUPS = ["kpn-merktaal-matter-of-fact", "kpn-merktaal-warm", "kpn-merktaal-confident", "kpn-merktaal-readability", "kpn-merktaal-style"];

const Evaluated = ({ assignments, annotationTypes, annotationCategories }) => {
  const documentType = useSelector(getDocumentType);
  const documentTypeName = DOCUMENT_TYPE_NAMES[documentType];

  const groupedAnnotationTypes = groupBy(annotationTypes, (annotationType) => annotationType.checkType);
  const groups = Object.keys(groupedAnnotationTypes);

  const annotationCategoryById = (id) => annotationCategories.find((annotationType) => annotationType.id === id);

  const scores = groups.map((group) => {
    const assignmentId = group === "kpn-merktaal-readability" ? "b1" : group.replace("kpn-merktaal-", "");
    const annotationCategory = annotationCategoryById(group);
    const score = scoreForAssignmentType(assignments, assignmentId);
    return {
      group,
      score,
      annotationCategory,
      isOk: score >= annotationCategory.minimumScores[documentType],
    };
  });

  const okScores = sortBy(
    scores.filter((score) => score.isOk),
    (score) => -1 * score.score
  );
  const notOkScores = sortBy(
    scores.filter((score) => !score.isOk),
    (score) => score.score
  );

  let clickToOpenText;
  if(notOkScores.length > 2) {
    clickToOpenText = `${notOkScores.map((score) => score.annotationCategory.display).slice(0, -1).join(", ")} of ${notOkScores[notOkScores.length - 1].annotationCategory.display}`;
  } else {
    clickToOpenText = notOkScores.map((score) => score.annotationCategory.display).join(" of ")
  }

  return <React.Fragment>
    <p>
      <G>Verbeter je tekst met Klinkende Taal.</G><br />
      Je hebt een {documentTypeName}-tekst
      geschreven. {okScores.length && "Je tekst scoort goed op:"}<br />
      {okScores.length && <React.Fragment>
        - {okScores.map((score) => score.annotationCategory.display).join(", ")}<br />
      </React.Fragment>}
      {notOkScores.length && <React.Fragment>
        Je tekst scoort nog niet goed op<br />
        - {notOkScores.map((score) => score.annotationCategory.display).join(", ")}<br />
        Klik {clickToOpenText} open. Je krijgt dan tips om de tekst te verbeteren.
      </React.Fragment>}
    </p>
  </React.Fragment>;
};

const MerktaalDescriptionText = ({ evaluated, assignments, annotationTypes, annotationCategories }) => {
  const enabledAnnotationCategory = useSelector(getSelectedAnnotationCategoryId);

  if(!evaluated) {
    return <Intro />;
  }

  switch(enabledAnnotationCategory) {
    case "kpn-merktaal-matter-of-fact" : {
      return <MatterOfFact
        assignments={assignments}
        annotationCategories={annotationCategories}
      />;
    }
    case "kpn-merktaal-warm" : {
      return <Warm
        assignments={assignments}
        annotationCategories={annotationCategories}
      />;
    }
    case "kpn-merktaal-confident" : {
      return <Confident
        assignments={assignments}
        annotationCategories={annotationCategories}
      />;
    }
    case "kpn-merktaal-readability" : {
      return <Readability
        assignments={assignments}
        annotationCategories={annotationCategories}
      />;
    }
    case "kpn-merktaal-style" : {
      return <Style
        assignments={assignments}
        annotationCategories={annotationCategories}
      />;
    }

    default: {
      return <Evaluated
        assignments={assignments}
        annotationTypes={annotationTypes}
        annotationCategories={annotationCategories}
      />;
    }
  }
}

const MerktaalDescription = (props) => {
  return <div className="bg-white rounded p-5 border-2 border-green space-y-2">
    <MerktaalDescriptionText {...props} />
  </div>
};

export default MerktaalDescription;
