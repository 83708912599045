import { getEnabledAnnotationTypes } from "./annotation_types_slice";
import { getSelectedAnnotationCategoryId } from "./selected_annotation_category_slice";

const syncAnnotationTypeDisabled = (ktEditor, selectedAnnotationCategoryId, enabledAnnotationTypes) => {
  if(ktEditor.loading) { return; }

  if(selectedAnnotationCategoryId) {
    const selectedCategoryAnnotationTypes = ktEditor.ktConfig.annotationTypes.filter(
      (annotationType) => annotationType.checkType === selectedAnnotationCategoryId
    ).map((annotationType) => annotationType.id);

    const highlightedAnnotationTypes = ktEditor.ktConfig.annotationTypes.filter(
      (annotationType) => annotationType.checkType === selectedAnnotationCategoryId && annotationType.display.markType === "highlight"
    ).map((annotationType) => annotationType.id);

    ktEditor.responseOptions.annotationTypes.forEach((annotationType) => {
      if(
        highlightedAnnotationTypes.includes(annotationType.id) || (
          selectedCategoryAnnotationTypes.includes(annotationType.id) &&
          enabledAnnotationTypes.has(annotationType.id)
        )
      ) {
        annotationType.disabled = false;
      } else {
        annotationType.disabled = true;
      }
    });
  } else {
    ktEditor.responseOptions.annotationTypes.forEach((annotationType) => annotationType.disabled = true);
  }
  ktEditor.render();
};

const merktaalMiddleware = (ktEditor) => {
  ktEditor.onConfigLoaded = () => {
    if(ktEditor.ktConfig == null) {
      return;
    }

    ktEditor.responseOptions.annotationTypes.forEach((annotationType) => annotationType.disabled = true);
  };

  return (store) => (next) => (action) => {
    const prevSelectedAnnotationCategoryId = getSelectedAnnotationCategoryId(store.getState());
    const prevEnabledAnnotationTypes = getEnabledAnnotationTypes(store.getState());

    const result = next(action);

    const selectedAnnotationCategoryId = getSelectedAnnotationCategoryId(store.getState());
    const enabledAnnotationTypes = getEnabledAnnotationTypes(store.getState());

    if(selectedAnnotationCategoryId !== prevSelectedAnnotationCategoryId || enabledAnnotationTypes !== prevEnabledAnnotationTypes) {
      syncAnnotationTypeDisabled(ktEditor, selectedAnnotationCategoryId, enabledAnnotationTypes);
    }

    return result;
  };
};

export default merktaalMiddleware;
