import { createSlice } from "@reduxjs/toolkit";

import { getSelectedAnnotationCategory } from "./merktaal_store";

const selectedAnnotationCategorySlice = createSlice({
  name: "selectedAnnotationCategory",
  initialState: {
    selected: null
  },
  reducers: {
    toggle: (state, action) => {
      if(action.payload === state.selected) {
        state.selected = null;
      } else {
        state.selected = action.payload;
      }
    },
    select: (state, action) => {
      state.selected = action.payload;
    },
  },
  extraReducers: {
    reset: (state, _action) => {
      state.selected = null;
    },
  },
});

const toggle = selectedAnnotationCategorySlice.actions.toggle;
const select = selectedAnnotationCategorySlice.actions.select;

export { selectedAnnotationCategorySlice, toggle, select };

export default selectedAnnotationCategorySlice.reducer;

export const getSelectedAnnotationCategoryId = (state) => getSelectedAnnotationCategory(state).selected;
