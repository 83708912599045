import React from "react";

const Bar = ({fillColor, filled, slider, sliderWidth, sliderHeight}) => {
  const height = "100%";
  const sliderPosition = slider * 100;

  const barWidth = `${filled > 1 ? 100 : filled * 100}%`;
  const sliderTop = `((((${sliderHeight}) - ${height})  / 2) * -1)`;
  const sliderLeft = `${sliderPosition}% - ((${sliderWidth}) / 2)`;

  return <div className="w-full border relative" style={{ height }}>
    <div className="h-full" style={{backgroundColor: fillColor, width: barWidth}}></div>
    <div className="absolute transition-all duration-300 flex flex-col items-center" style={{width: sliderWidth, height: `calc(${sliderHeight})`, top: `calc(${sliderTop})`, left: `calc(${sliderLeft})`}}>
      <div className="w-full bg-black" style={{height: `calc(100% * (1/6))`}}></div>
      <div className="w-1/2 bg-black flex-grow" ></div>
      <div className="w-full bg-black" style={{height: `calc(100% * (1/6))`}}></div>
    </div>
  </div>;
};

export default Bar;
