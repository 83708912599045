import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useSelector, useDispatch } from "react-redux";

import { getDocumentType, change } from "../lib/document_type_slice";

const MerktaalDocumentControls = ({ editor }) => {
  const dispatch = useDispatch();
  const documentType = useSelector(getDocumentType);

  const handleEvaluateButtonClick = (event) => {
    event.preventDefault();

    editor.requestOptions.checkType = undefined;
    editor.clearAnnotations();
    editor.performCheck();
  };
  const handleDocumentTypeChange = (event) => {
    dispatch(change(event.target.value));
  };

  return <React.Fragment>
    <span className="hidden xl:inline text-right">
      LVE | KLINKENDE{"\u00A0"}TAAL
    </span>
    <select className="border rounded py-1 px-2 ml-2 xl:ml-10 mr-2 documenttype w-full xl:w-auto" value={documentType} onChange={handleDocumentTypeChange}>
      <option value="inspire">Inspireren en verleiden</option>
      <option value="argue">Argumenteren</option>
      <option value="associate">Associëren</option>
      <option value="inform">Informeren</option>
    </select>
    <span className="flex justify-center items-center">
      <span className="w-6 flex justify-center items-center">
        { editor.checking &&
          <FontAwesomeIcon icon={["fad", "spinner-third"]} spin />
        }
      </span>
      <button className="button primary-button left-button right-button ml-2 evaluate" onClick={handleEvaluateButtonClick} disabled={editor.checking}>Evalueer</button>
    </span>
  </React.Fragment>;
};

export default MerktaalDocumentControls;
