import { createSlice } from "@reduxjs/toolkit";

import { getDocumentType as getDocumentTypeRoot } from "./merktaal_store";

export const documentTypeSlice = createSlice({
  name: "documentType",
  initialState: {
    documentType: "inspire"
  },
  reducers: {
    change: (state, action) => {
      state.documentType = action.payload;
    }
  }
});

export const change = documentTypeSlice.actions.change;

export default documentTypeSlice.reducer;

export const getDocumentType = (state) => getDocumentTypeRoot(state).documentType;
